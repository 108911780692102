export const PROMO_SECTION_TYPE = {
    LOGO: 1 << 0,
    GENERAL_INFO: 1 << 1,
    BRANDS: 1 << 2,
    STATISTICS: 1 << 3,
    STORIES: 1 << 4,
    EARN_PROCESS: 1 << 5,
    COMMISSION: 1 << 6,
    MARKETING_TOOLS: 1 << 7,
    TESTIMONIAL: 1 << 8,
    FAQ: 1 << 9,
    FOOTER: 1 << 10,
    SEO: 1 << 11,
    COMMON: 1 << 12
}

export const PROMO_FILE_TYPE = {
    LOGO: 1,
    FAVICON: 2,
    BANNER: 3,
    BACKGROUND: 4,
    BACKGROUND_1: 5,
    BACKGROUND_2: 6,
    BACKGROUND_3: 7,
    BACKGROUND_4: 8,
    BACKGROUND_5: 9,
    BACKGROUND_6: 10,
    BACKGROUND_7: 11,
    BACKGROUND_8: 12,
    BACKGROUND_9: 13,
    BACKGROUND_10: 14,
    LOGO_1: 15,
    LOGO_2: 16,
    LOGO_3: 17,
    LOGO_4: 18,
    LOGO_5: 19,
    LOGO_6: 20,
    LOGO_7: 21,
    LOGO_8: 22,
    LOGO_9: 23,
    LOGO_10: 24
}

export const PROMO_FILE_NAMES = {
    [PROMO_FILE_TYPE.LOGO]: "logo",
    [PROMO_FILE_TYPE.FAVICON]: "favicon",
    [PROMO_FILE_TYPE.BANNER]: "banner",
    [PROMO_FILE_TYPE.BACKGROUND]: "background",
    [PROMO_FILE_TYPE.BACKGROUND_1]: "background1",
    [PROMO_FILE_TYPE.BACKGROUND_2]: "background2",
    [PROMO_FILE_TYPE.BACKGROUND_3]: "background3",
    [PROMO_FILE_TYPE.BACKGROUND_4]: "background4",
    [PROMO_FILE_TYPE.BACKGROUND_5]: "background5",
    [PROMO_FILE_TYPE.BACKGROUND_6]: "background6",
    [PROMO_FILE_TYPE.BACKGROUND_7]: "background7",
    [PROMO_FILE_TYPE.BACKGROUND_8]: "background8",
    [PROMO_FILE_TYPE.BACKGROUND_9]: "background9",
    [PROMO_FILE_TYPE.BACKGROUND_10]: "background10",
    [PROMO_FILE_TYPE.LOGO_1]: "logo1",
    [PROMO_FILE_TYPE.LOGO_2]: "logo2",
    [PROMO_FILE_TYPE.LOGO_3]: "logo3",
    [PROMO_FILE_TYPE.LOGO_4]: "logo4",
    [PROMO_FILE_TYPE.LOGO_5]: "logo5",
    [PROMO_FILE_TYPE.LOGO_6]: "logo6",
    [PROMO_FILE_TYPE.LOGO_7]: "logo7",
    [PROMO_FILE_TYPE.LOGO_8]: "logo8",
    [PROMO_FILE_TYPE.LOGO_9]: "logo9",
    [PROMO_FILE_TYPE.LOGO_10]: "logo10",
}

export const PROMO_DEFAULT_CONFIGURATION = {
    FONT_FAMILY: "Sf-Pro-Display, sans-serif",
    BACKGROUND_COLOR: "#121212",
    BRAND_COLOR: "#FFAE2B",
    SECONDARY_COLOR: "#F8511A",
    TEXT_AND_ICON_COLOR: "#636468",
    FAVICON: "/assets/images/favicon.svg",
    LOGO: "system/promo/images/site-logo.svg",
    GENERAL_BANNER: "system/promo/images/header-image.png",
    GENERAL_BG: "system/promo/images/header-bg.png",
    EARN_PROCESS_BG: "system/promo/images/earn-process-bg.png",
    MARKETING_TOOLS_BG: "system/promo/images/marketing-tools-bg.png",
    MARKETING_TOOLS_BANNER: "system/promo/images/marketing-tools-banner.png",
    MARKETING_TOOLS_LOGO: "system/promo/images/marketing-tools-logo.svg",
    MARKETING_TOOLS_BACKGROUND_1: "system/promo/images/marketing-image-1.png",
    MARKETING_TOOLS_BACKGROUND_2: "system/promo/images/marketing-image-2.png",
    MARKETING_TOOLS_BACKGROUND_3: "system/promo/images/marketing-image-3.png",
    MARKETING_TOOLS_BACKGROUND_4: "system/promo/images/marketing-image-4.png",
    MARKETING_TOOLS_BACKGROUND_5: "system/promo/images/marketing-image-5.png",
    TESTIMONIALS_BG: "system/promo/images/testimonial-bg.png",
    TESTIMONIALS_LOGO: "system/promo/images/testimonial-user.png",
    FAQ_BG: "system/promo/images/faq-bg.png",
}

export const PROMO_UPDATE_TYPE = {
    COLORS: 1,
    FONT_FAMILY: 2,
    IMAGE: 3,
    DYNAMIC_DATA: 4,
    SECTION: 5,
    TEXT: 6,
    SCROLL_TO_SECTION: 7,
    CONTACTS: 8,
    LANGUAGE: 9,
    LANGUAGES_LIST: 10,
    DEFAULT_LANGUAGE_CODE: 11
}
