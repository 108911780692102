import axios from "axios";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { initTranslations } from 'translations/config';

import {
    SET_TRANSLATIONS_LOADED,
    SET_NOT_FOUND,
    SET_CONFIGURATION_ACTION_BEFORE,
    SET_CONFIGURATION_ACTION_FINISH,
    SET_CONFIGURATION,
    SET_CONFIGURATION_DYNAMIC_DATA,
    SET_CONFIGURATION_ENABLED,
    SET_CONFIGURATION_CONTACTS,
    SET_CONFIGURATION_LANGUAGES,
    SET_CONFIGURATION_DEFAULT_LANGUAGE_CODE,
    SET_TRANSLATION
} from "store/actionTypes";

import { isPreviewMode } from "utils/common";
import { getPromoSectionName } from "utils/promo";

export const setTranslationsLoaded = () => ({
	type: SET_TRANSLATIONS_LOADED,
});

export const setNotFound = () => ({
	type: SET_NOT_FOUND,
});


const setConfigurationActionBefore = () => ({
    type: SET_CONFIGURATION_ACTION_BEFORE,
});

const setConfigurationActionFinished = () => ({
    type: SET_CONFIGURATION_ACTION_FINISH,
});

const setConfiguration = configuration => ({
    type: SET_CONFIGURATION,
    payload: { configuration }
})

const setConfigurationDynamicData = (section, dynamicData) => ({
    type: SET_CONFIGURATION_DYNAMIC_DATA,
    payload: { section, dynamicData }
})

const setConfigurationEnabled = (section, enabled) => ({
    type: SET_CONFIGURATION_ENABLED,
    payload: { section, enabled }
})

const setConfigurationContacts = contacts => ({
    type: SET_CONFIGURATION_CONTACTS,
    payload: { contacts }
})

const setTranslation = (section, key, text) => ({
    type: SET_TRANSLATION,
    payload: { section, key, text }
})

const setLanguages = languages => ({
    type: SET_CONFIGURATION_LANGUAGES,
    payload: { languages }
})

const setDefaultLanguageCode = language => ({
    type: SET_CONFIGURATION_DEFAULT_LANGUAGE_CODE,
    payload: { language }
})

export const getConfiguration = languageCode => {
    return dispatch => {
        dispatch(setConfigurationActionBefore());

        const params = {
            languageCode: languageCode
        };

        if(isPreviewMode()){
            const searchParams = new URLSearchParams(window.location.search);
            params.id = searchParams.get("id");
        }

        return axios({
            url: ApiUrls.GET_CONFIGURATION,
            method: Methods.GET,
            params: params
        })
        .then(({data : {value: configuration}}) => {
            dispatch(setConfiguration(configuration));
            const language = (languageCode ?? configuration.defaultLanguageCode).toLowerCase();

            initTranslations( language, `${configuration.translationFolder}/${language}.json` );
            dispatch(setConfigurationActionFinished());
        })
        .catch((ex) => {
            dispatch(setConfigurationActionFinished());
        })
    }
}

export const updateConfigurationDynamicData = (sectionType, data) => {
    return dispatch => {
        const dynamicData = data ? JSON.stringify(data) : data;
        const section = getPromoSectionName(sectionType);
        dispatch(setConfigurationDynamicData(section, dynamicData));
    }
}

export const updateConfigurationEnabled = (sectionType, enabled) => {
    return dispatch => {
        const section = getPromoSectionName(sectionType);
        dispatch(setConfigurationEnabled(section, enabled));
    }
}

export const updateTranslation = (sectionType, key, text) => {
    return dispatch => {
        const section = getPromoSectionName(sectionType);

        dispatch(setTranslation(section, key, text));
    }
}

export const updateConfigurationContacts = contacts => {
    return dispatch => {
        dispatch(setConfigurationContacts(contacts));
    }
}

export const updateLanguages = languages => {
    return dispatch => {
        dispatch(setLanguages(languages));
    }
}

export const updateDefaultLanguageCode = language => {
    return dispatch => {
        dispatch(setDefaultLanguageCode(language));
    }
}