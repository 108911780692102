import {
    SET_TRANSLATIONS_LOADED,
    SET_NOT_FOUND,
    SET_CONFIGURATION_ACTION_BEFORE,
    SET_CONFIGURATION_ACTION_FINISH,
    SET_CONFIGURATION,
    SET_CONFIGURATION_DYNAMIC_DATA,
    SET_CONFIGURATION_ENABLED,
    SET_CONFIGURATION_CONTACTS,
    SET_CONFIGURATION_LANGUAGES,
    SET_CONFIGURATION_DEFAULT_LANGUAGE_CODE,
    SET_TRANSLATION
} from "store/actionTypes";

export default (state = {}, { type, payload }) => {
    switch (type) {
        case SET_TRANSLATIONS_LOADED:
            return {
                ...state,
                translationsLoaded: true
            }
        case SET_NOT_FOUND:
            return {
                ...state,
                notFound: true
            }
        case SET_CONFIGURATION_ACTION_BEFORE:
            return {
                ...state,
                isLoading: true
            }
        case SET_CONFIGURATION_ACTION_FINISH:
            return {
                ...state,
                isLoading: false
            }
        case SET_CONFIGURATION:
            return {
                ...state,
                configuration: payload.configuration
            }
        case SET_CONFIGURATION_DYNAMIC_DATA:
            return {
                ...state,
                configuration: {
                    ...state.configuration,
                    sections: {
                        ...state.configuration.sections,
                        [payload.section]: {
                            ...state.configuration.sections[payload.section],
                            dynamicData: payload.dynamicData
                        }
                    }
                }
            }
        case SET_CONFIGURATION_ENABLED:
            return {
                ...state,
                configuration: {
                    ...state.configuration,
                    sections: {
                        ...state.configuration.sections,
                        [payload.section]: {
                            ...state.configuration.sections[payload.section],
                            enabled: payload.enabled
                        }
                    }
                }
            }
        case SET_CONFIGURATION_CONTACTS:
            return {
                ...state,
                configuration: {
                    ...state.configuration,
                    contactSection: {
                        ...state.configuration.contactSection,
                        contacts: payload.contacts
                    }
                }
            }
        case SET_CONFIGURATION_LANGUAGES:
            return {
                ...state,
                configuration: {
                    ...state.configuration,
                    languages: payload.languages
                }
            }
        case SET_CONFIGURATION_DEFAULT_LANGUAGE_CODE:
            return {
                ...state,
                configuration: {
                    ...state.configuration,
                    defaultLanguageCode: payload.language
                }
            }
        case SET_TRANSLATION:
            return {
                ...state,
                translations: {
                    ...state.translations,
                    [payload.section]: {
                        ...(state.translations[payload.section] ? state.translations[payload.section] : {}),
                        [payload.key]: payload.text
                    }
                }
            }
        default:
            return state
    }
}