import React, { useMemo } from 'react';

import { connect } from "react-redux";
import { useParams } from 'react-router-dom';

import useText from 'hooks/useText';

import { PROMO_SECTION_TYPE } from 'constants/common.constants';

import { inIframe } from 'utils/common';

import configurationType from 'types/configuration.type';

const General = ({
    configuration
}) => {
    const { t } = useText();

    const searchParams = useParams();

    const currentLn = useMemo(() => (searchParams.lang || (configuration.defaultLanguageCode ?? "")).toUpperCase(), [])

    const becomeAffiliate = () => {
        if (configuration?.portalDomain && !inIframe()) {
            window.location.href = `https://${configuration?.portalDomain}/register?id=${configuration.id}&language=${currentLn.toLowerCase()}`
        }
    }

    return (
        <div className="rt--promo-header" id={PROMO_SECTION_TYPE.GENERAL_INFO}>
            <div className='rt--promo-header-block rt--promo-block-gap'>
                <div className='rt--promo-header-block-content rt--flex rt--align-center rt--justify-between'>
                    <div className='rt--promo-header-block-content-info rt--flex rt--flex-col rt--align-start rt--pr-20'>
                        <span className='rt--header-title-color rt--font-64 rt--font-extra-bold rt--mb-20'>
                            {
                                t(PROMO_SECTION_TYPE.GENERAL_INFO, "title")
                            }
                        </span>
                        <span className='rt--subtitle-color rt--font-14 rt--font-bold  rt--mb-44'>
                            {
                                t(PROMO_SECTION_TYPE.GENERAL_INFO, "description")
                            }
                        </span>
                        <button 
                            className='rt--promo-button rt--promo-button-primary'
                            onClick={becomeAffiliate}
                        >
                            {
                                t(PROMO_SECTION_TYPE.GENERAL_INFO, "button")
                            }
                        </button>
                    </div>
                    <div className='rt--promo-header-block-content-img'></div>
                </div>
            </div>
        </div>
    );
}

/** General propTypes
    * PropTypes
*/
General.propTypes = {
    /** Redux state property, configuration */
    configuration: configurationType
}

const mapStateToProps = (state) => {
    return {
        configuration: state.common.configuration
    };
};

export default connect(mapStateToProps, null)(General);