import React from 'react';

/** NotFound Component */
const NotFound = () => {

    return (
        <div className='rt--not-found rt--flex rt--align-center rt--justify-center'>
            <h1>PROMO NOT FOUND</h1>
        </div>

    )
}

export default NotFound;