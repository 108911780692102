import React from 'react';

import useText from 'hooks/useText';

import { PROMO_SECTION_TYPE } from 'constants/common.constants';

/** Privacy Page Component */
const PrivacyComponent = () => {

    const { t } = useText();

    return (
        <div className="rt--promo-terms" id='privacy'>
            <div className='rt--promo-terms-block rt--promo-block-gap'>
                <div className='rt--promo-terms-content'>
                    <div className='rt--promo-terms-content-title'>
                        <span className='rt--title-color rt--font-36 rt--font-extra-bold'>
                            {
                                t(PROMO_SECTION_TYPE.COMMON, "privacyPolicy")
                            }
                        </span>
                    </div>
                    <div
                        dangerouslySetInnerHTML={{ __html: t(PROMO_SECTION_TYPE.FOOTER, "description2") }}
                        className='rt--promo-terms-block-content-info rt--background-color rt--mt-24'>
                    </div>
                </div>
            </div>
        </div>
    );
}


export default PrivacyComponent;