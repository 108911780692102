import PropTypes from 'prop-types';

export default PropTypes.shape({
    colors: PropTypes.shape({
        backgroundColor: PropTypes.string,
        brandColor: PropTypes.string,
        secondaryColor: PropTypes.string,
        textAndIconColor: PropTypes.string
    }),
    defaultLanguageCode: PropTypes.string,
    fontFamily: PropTypes.string,
    if: PropTypes.string,
    longId: PropTypes.number,
    logoSection: PropTypes.shape({
        files: PropTypes.shape({
            logo: PropTypes.string,
            favicon: PropTypes.string
        })
    }),
    sections: PropTypes.objectOf(PropTypes.shape({
        dynamicData: PropTypes.string,
        enabled: PropTypes.bool,
        files: PropTypes.shape({
            background: PropTypes.string,
            banner: PropTypes.string
        })
    }))
})