import React from 'react';

import PropTypes from 'prop-types';

import arFlag from "assets/images/flags/ar.svg";
import bgFlag from "assets/images/flags/bg.svg";
import deFlag from "assets/images/flags/de.svg";
import enFlag from "assets/images/flags/en.svg";
import esFlag from "assets/images/flags/es.svg";
import faFlag from "assets/images/flags/fa.svg";
import frFlag from "assets/images/flags/fr.svg";
import heFlag from "assets/images/flags/he.svg";
import hyFlag from "assets/images/flags/hy.svg";
import koFlag from "assets/images/flags/ko.svg";
import ptFlag from "assets/images/flags/pt.svg";
import roFlag from "assets/images/flags/ro.svg";
import ruFlag from "assets/images/flags/ru.svg";
import trFlag from "assets/images/flags/tr.svg";

const mapping = {
    "ar": arFlag,
    "bg": bgFlag,
    "de": deFlag,
    "en": enFlag,
    "es": esFlag,
    "fa": faFlag,
    "fr": frFlag,
    "he": heFlag,
    "hy": hyFlag,
    "ko": koFlag,
    "pt": ptFlag,
    "ro": roFlag,
    "ru": ruFlag,
    "tr": trFlag
}


/** Flag Component */
const Flag = ({ 
    code,
    className
}) => {

    const flagImg = code ? mapping[code.toLowerCase()] : null

    return (
        <div
            className={"rt--flag " + (className ?? "")}
        >
            <div 
                className="rt--flag-icon" 
                style={
                    flagImg ? { 
                        backgroundImage: `url(${flagImg})`
                    } : {}
                }
            />
        </div>
    )
}

/** Flag propTypes
    * PropTypes
*/
Flag.propTypes = {
    /** Flag code */
    code: PropTypes.string,
    /** For add styles to container */
    className: PropTypes.string,
}

export default Flag;