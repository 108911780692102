import React from 'react';

import { Provider } from 'react-redux';

import { RouterProvider } from 'react-router-dom';

import 'translations/config';

import store from 'store/configureStore';
import router from 'routes/router';

import 'utils/axios';

store.subscribe(() => { });

const App = () => {

	return (
		<Provider store={store}>
			<div className="App">
				<RouterProvider router={router} />
			</div>
		</Provider>
	)
}

export default App;