import { useSelector } from 'react-redux';

import i18n from 'translations/config';

import { isPreviewMode } from 'utils/common';
import { getPromoSectionName } from 'utils/promo';

import { PROMO_SECTION_TYPE } from 'constants/common.constants';

const selectTranslations = state => state.common.translations;

const useText = () => {

    //#region ------------------------------------------- REDUX --------------------------------------------//

    const translations = useSelector(selectTranslations);

    //#endregion

    const t = (sectionType, key) => {
        const sectionName = getPromoSectionName(sectionType);
        const sectionNameKey = sectionName.toLowerCase()
        if(!isPreviewMode() || sectionType === PROMO_SECTION_TYPE.COMMON){
            return i18n.t(`promo.${sectionNameKey}.${key}`)
        }
        if(translations?.[sectionName]?.[key]){
            return translations?.[sectionName]?.[key];
        }

        return i18n.t(`promo.${sectionNameKey}.${key}`)
    }

    return {
        t
    }
}

export default useText;