import React from 'react';

/** Loader Component */
const Loader = () => {

    return (
        <div className="rt--promo-loading">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    )
}

export default Loader;