import React from 'react';

import { Route, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';

import Paths from 'constants/path.constants';

// Layouts

import RootLayout from 'layouts/root.layout';
import MainLayout from 'layouts/main.layout';

// Routes
import MainRoute from './main.route';
const router = createBrowserRouter(
    createRoutesFromElements(
        <Route path={Paths.DEFAULT} element={<RootLayout />} >
            <Route path={Paths.MAIN} element={<MainLayout />} >
                <Route path="" element={<MainRoute />} />
            </Route>
        </Route>
    )
)

export default router;