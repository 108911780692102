import i18n from 'i18next';

import { message } from 'antd';

import { RESPONSE_CODES } from 'constants/responseCode.constants';

/** Get error text
     * @function
     * @param {string} msg - error message
     * @param {string} status - error status
     * @param {string} resource - error resource
     * @param {string} fullMessage - fullMessage
     * @returns {string}
*/
export const getErrorText = (msg, status, resource) => {
    if (!status) return msg;

    if(!Object.values(RESPONSE_CODES).includes(status)) return msg;
    let text = "";

    const st = Object.keys(RESPONSE_CODES).find(key => RESPONSE_CODES[key] === status);
    text = i18n.t(`promo.common.${st}`).replace("%resource%", resource && resource !== "None" ? i18n.t(`promo.common.${resource}`) : "");

    text = text || "";

    return text;
}

/** Show error notification
     * @function
     * @param {string} msg - error message
     * @param {string} status - error status
     * @param {string} resource - error resource
*/

export const showError = (msg, status, resource) => {
    message.error(getErrorText(msg, status, resource));
}
