/** Common */

export const SET_TRANSLATIONS_LOADED = "SET_TRANSLATIONS_LOADED";
export const SET_NOT_FOUND = "SET_NOT_FOUND";
export const SET_CONFIGURATION_ACTION_BEFORE = "SET_CONFIGURATION_ACTION_BEFORE";
export const SET_CONFIGURATION_ACTION_FINISH = "SET_CONFIGURATION_ACTION_FINISH";
export const SET_CONFIGURATION = "SET_CONFIGURATION";
export const SET_CONFIGURATION_DYNAMIC_DATA = "SET_CONFIGURATION_DYNAMIC_DATA";
export const SET_CONFIGURATION_ENABLED = "SET_CONFIGURATION_ENABLED";
export const SET_CONFIGURATION_CONTACTS = "SET_CONFIGURATION_CONTACTS";
export const SET_CONFIGURATION_LANGUAGES = "SET_CONFIGURATION_LANGUAGES";
export const SET_CONFIGURATION_DEFAULT_LANGUAGE_CODE = "SET_CONFIGURATION_DEFAULT_LANGUAGE_CODE";
export const SET_TRANSLATION = "SET_TRANSLATION";
