/** Get api urls depend on domain
     * @function
     * @returns {object} - urls
 */
export const getAPIUrls = () => {
    const currentOrigin = window.location.origin;
    if (
        currentOrigin.includes("localhost") || 
        (
            currentOrigin.startsWith("http://aff-admin-client.") &&
            currentOrigin.endsWith(".digi.loc")
        )
    ) {
        return {
            API_URL: import.meta.env.SYSTEM_API_URL_LOC
        }
    }

    return {
        API_URL: import.meta.env.SYSTEM_API_URL_COM
    }
}

/** Get query params
     * @function
     * @param {string} path - location path
     * @returns {object} - query params
 */
export const getUrlVars = path => {
    const href = path || window.location.href;
    const vars = {};
    href.replace(/[?&]+([^=&]+)=([^&]*)/gi,
        (m, key, value) => {
            vars[key] = value ? value.split("#")[0] : value;
        }
    );
    return vars;
}

/** Check if device is mobile
     * @function
     * @returns {boolean}
 */
export const isMobile = () => {
    const isMobileBrowser = {
        Android: () => navigator.userAgent.match(/Android/i) !== null,
        BlackBerry: () => navigator.userAgent.match(/BlackBerry/i) !== null,
        iOS: () => navigator.userAgent.match(/iPhone|iPad|iPod/i) !== null || (navigator.userAgent.includes("Mac") && "ontouchend" in document),
        Opera: () => navigator.userAgent.match(/Opera Mini/i) !== null,
        Windows: () => navigator.userAgent.match(/IEMobile/i) !== null || navigator.userAgent.match(/WPDesktop/i) !== null,
        any: () => isMobileBrowser.Android() || isMobileBrowser.BlackBerry() || isMobileBrowser.iOS() || isMobileBrowser.Opera() || isMobileBrowser.Windows()
    };
    return isMobileBrowser.any() || getUrlVars()["isMobile"] === "true";
};

export const debounce = (cb, delay = 300) => {
    let timer = null;

    return (...args) => {
        clearTimeout(timer);

        timer = setTimeout(() => {
            cb.apply(this, args);
        }, delay);
    }
}

/** Cheks if the page is RTL
      * @function
      * @param {string} language - the language
      * @returns {boolean}
 */
export const isRTL = language => {
    const farsiLanguages = ['FA'];
    return farsiLanguages.includes(language);
}

/** Is Preview Mode */
export const isPreviewMode = () => {
    const searchParams = new URLSearchParams(window.location.search);
    return searchParams.has("id");
}

/** Is in iframe */
export const inIframe = () => {
    try {
        return window.self !== window.top;
    } catch (e) {
        return true;
    }
}

/** Make Contact URL */
export const makeContactUrl = (type, value) => {
    switch (type) {
        case "email":
            return `mailto:${value}`;
        case "facebook":
            return `https://facebook.com/${value}`;
        case "instagram":
            return `https://instagram.com/${value}`;
        case "skype":
            return `skype:${value}`;
        case "telegram":
            return `https://t.me/${value}`;
        case "twitter":
            return `https://twitter.com/${value}`;
        case "viber":
            return `https://viber.me/${value}`;
        case "whatsApp":
            return `https://wa.me/${value}`;
        default:
            break;
    }
}

