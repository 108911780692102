import Values from 'values.js';

import FAVICON_IMG from "assets/images/favicon.svg";

import { PROMO_DEFAULT_CONFIGURATION, PROMO_FILE_NAMES, PROMO_FILE_TYPE, PROMO_SECTION_TYPE } from "constants/common.constants";

export const makeImagePath = path => `${import.meta.env.SYSTEM_CDN_URL}/${path}`.toLowerCase();

export const makeCssURL = url => `url(${url})`;

export const lightenColor = (color, precent) => {
    const c = new Values(color);
    const lighten = c.tint(precent);
    return lighten.hexString();
}

export const createFontFamilyVariable = family => {
    const fontFamily = family ?? PROMO_DEFAULT_CONFIGURATION.FONT_FAMILY;
    document.documentElement.style.setProperty('--font-family', fontFamily);
}

export const createColorVariables = colors => {
    const backgroundColor = colors?.backgroundColor ?? PROMO_DEFAULT_CONFIGURATION.BACKGROUND_COLOR;
    const brandColor = colors?.brandColor ?? PROMO_DEFAULT_CONFIGURATION.BRAND_COLOR;
    const secondaryColor = colors?.secondaryColor ?? PROMO_DEFAULT_CONFIGURATION.SECONDARY_COLOR;
    const textAndIconColor = colors?.textAndIconColor ?? PROMO_DEFAULT_CONFIGURATION.TEXT_AND_ICON_COLOR;
    const textAndIconColor20 = lightenColor(textAndIconColor, 20);
    const textAndIconColor40 = lightenColor(textAndIconColor, 40);
    const textAndIconColor60 = lightenColor(textAndIconColor, 60);
    const textAndIconColor70 = lightenColor(textAndIconColor, 70);
    const textAndIconColor80 = lightenColor(textAndIconColor, 80);

    document.documentElement.style.setProperty('--main-brand-color', brandColor);
    document.documentElement.style.setProperty('--main-bg-color', backgroundColor);
    document.documentElement.style.setProperty('--main-secondary-color', secondaryColor);
    document.documentElement.style.setProperty('--main-text-color', textAndIconColor);
    document.documentElement.style.setProperty('--main-text-color-20', textAndIconColor20);
    document.documentElement.style.setProperty('--main-text-color-40', textAndIconColor40);
    document.documentElement.style.setProperty('--main-text-color-60', textAndIconColor60);
    document.documentElement.style.setProperty('--main-text-color-70', textAndIconColor70);
    document.documentElement.style.setProperty('--main-text-color-80', textAndIconColor80);
}

export const createImageVariable = file => {
    const variables = {
        [PROMO_SECTION_TYPE.LOGO]: {
            [PROMO_FILE_TYPE.LOGO]: {
                variableName: '--logo-image',
                defaultValue: PROMO_DEFAULT_CONFIGURATION.LOGO
            },
            [PROMO_FILE_TYPE.FAVICON]: {
                variableName: null,
                defaultValue: PROMO_DEFAULT_CONFIGURATION.FAVICON
            },
        },
        [PROMO_SECTION_TYPE.GENERAL_INFO]: {
            [PROMO_FILE_TYPE.BACKGROUND]: {
                variableName: '--header-bg',
                defaultValue: PROMO_DEFAULT_CONFIGURATION.GENERAL_BG
            },
            [PROMO_FILE_TYPE.BANNER]: {
                variableName: '--header-banner',
                defaultValue: PROMO_DEFAULT_CONFIGURATION.GENERAL_BANNER
            },
        },
        [PROMO_SECTION_TYPE.EARN_PROCESS]: {
            [PROMO_FILE_TYPE.BACKGROUND]: {
                variableName: '--earn-process-bg',
                defaultValue: PROMO_DEFAULT_CONFIGURATION.EARN_PROCESS_BG
            },
        },
        [PROMO_SECTION_TYPE.MARKETING_TOOLS]: {
            [PROMO_FILE_TYPE.BACKGROUND]: {
                variableName: '--marketing-tools-bg',
                defaultValue: PROMO_DEFAULT_CONFIGURATION.MARKETING_TOOLS_BG
            },
            [PROMO_FILE_TYPE.BANNER]: {
                variableName: '--marketing-tools-banner',
                defaultValue: PROMO_DEFAULT_CONFIGURATION.MARKETING_TOOLS_BANNER
            },

            [PROMO_FILE_TYPE.LOGO_1]: {
                variableName: '--marketing-tools-logo-1',
                defaultValue: PROMO_DEFAULT_CONFIGURATION.MARKETING_TOOLS_LOGO
            },
            [PROMO_FILE_TYPE.LOGO_2]: {
                variableName: '--marketing-tools-logo-2',
                defaultValue: PROMO_DEFAULT_CONFIGURATION.MARKETING_TOOLS_LOGO
            },
            [PROMO_FILE_TYPE.LOGO_3]: {
                variableName: '--marketing-tools-logo-3',
                defaultValue: PROMO_DEFAULT_CONFIGURATION.MARKETING_TOOLS_LOGO
            },
            [PROMO_FILE_TYPE.LOGO_4]: {
                variableName: '--marketing-tools-logo-4',
                defaultValue: PROMO_DEFAULT_CONFIGURATION.MARKETING_TOOLS_LOGO
            },
            [PROMO_FILE_TYPE.LOGO_5]: {
                variableName: '--marketing-tools-logo-5',
                defaultValue: PROMO_DEFAULT_CONFIGURATION.MARKETING_TOOLS_LOGO
            },
            [PROMO_FILE_TYPE.BACKGROUND_1]: {
                variableName: '--marketing-tools-background-1',
                defaultValue: PROMO_DEFAULT_CONFIGURATION.MARKETING_TOOLS_BACKGROUND_1
            },
            [PROMO_FILE_TYPE.BACKGROUND_2]: {
                variableName: '--marketing-tools-background-2',
                defaultValue: PROMO_DEFAULT_CONFIGURATION.MARKETING_TOOLS_BACKGROUND_2
            },
            [PROMO_FILE_TYPE.BACKGROUND_3]: {
                variableName: '--marketing-tools-background-3',
                defaultValue: PROMO_DEFAULT_CONFIGURATION.MARKETING_TOOLS_BACKGROUND_3
            },
            [PROMO_FILE_TYPE.BACKGROUND_4]: {
                variableName: '--marketing-tools-background-4',
                defaultValue: PROMO_DEFAULT_CONFIGURATION.MARKETING_TOOLS_BACKGROUND_4
            },
            [PROMO_FILE_TYPE.BACKGROUND_5]: {
                variableName: '--marketing-tools-background-5',
                defaultValue: PROMO_DEFAULT_CONFIGURATION.MARKETING_TOOLS_BACKGROUND_5
            },
        },
        [PROMO_SECTION_TYPE.TESTIMONIAL]: {
            [PROMO_FILE_TYPE.BACKGROUND]: {
                variableName: '--testimonial-bg',
                defaultValue: PROMO_DEFAULT_CONFIGURATION.TESTIMONIALS_BG
            },
            [PROMO_FILE_TYPE.LOGO_1]: {
                variableName: '--testimonial-logo-1',
                defaultValue: PROMO_DEFAULT_CONFIGURATION.TESTIMONIALS_LOGO
            },
            [PROMO_FILE_TYPE.LOGO_2]: {
                variableName: '--testimonial-logo-2',
                defaultValue: PROMO_DEFAULT_CONFIGURATION.TESTIMONIALS_LOGO
            },
            [PROMO_FILE_TYPE.LOGO_3]: {
                variableName: '--testimonial-logo-3',
                defaultValue: PROMO_DEFAULT_CONFIGURATION.TESTIMONIALS_LOGO
            },
            [PROMO_FILE_TYPE.LOGO_4]: {
                variableName: '--testimonial-logo-4',
                defaultValue: PROMO_DEFAULT_CONFIGURATION.TESTIMONIALS_LOGO
            },
            [PROMO_FILE_TYPE.LOGO_5]: {
                variableName: '--testimonial-logo-5',
                defaultValue: PROMO_DEFAULT_CONFIGURATION.TESTIMONIALS_LOGO
            },
            [PROMO_FILE_TYPE.LOGO_6]: {
                variableName: '--testimonial-logo-6',
                defaultValue: PROMO_DEFAULT_CONFIGURATION.TESTIMONIALS_LOGO
            },
            [PROMO_FILE_TYPE.LOGO_7]: {
                variableName: '--testimonial-logo-7',
                defaultValue: PROMO_DEFAULT_CONFIGURATION.TESTIMONIALS_LOGO
            },
            [PROMO_FILE_TYPE.LOGO_8]: {
                variableName: '--testimonial-logo-8',
                defaultValue: PROMO_DEFAULT_CONFIGURATION.TESTIMONIALS_LOGO
            },
            [PROMO_FILE_TYPE.LOGO_9]: {
                variableName: '--testimonial-logo-9',
                defaultValue: PROMO_DEFAULT_CONFIGURATION.TESTIMONIALS_LOGO
            },
            [PROMO_FILE_TYPE.LOGO_10]: {
                variableName: '--testimonial-logo-10',
                defaultValue: PROMO_DEFAULT_CONFIGURATION.TESTIMONIALS_LOGO
            },
        },
        [PROMO_SECTION_TYPE.FAQ]: {
            [PROMO_FILE_TYPE.BACKGROUND]: {
                variableName: '--faq-bg',
                defaultValue: PROMO_DEFAULT_CONFIGURATION.FAQ_BG
            },
        }
    }

    if (file && file.path !== undefined) {
        const variable = variables[file.sectionType]?.[file.fileType];
        if (variable) {
            const variableName = variable.variableName;
            const defaultValue = variable.defaultValue;
            if (file.fileType !== PROMO_FILE_TYPE.FAVICON) {
                if (file.path !== null) {
                    document.documentElement.style.setProperty(variableName, makeCssURL(makeImagePath(file.path)));
                } else {
                    document.documentElement.style.setProperty(variableName, makeCssURL(makeImagePath(defaultValue)));
                }
            } else {
                let link = document.querySelector("link[rel~='icon']");
                if (!link) {
                    link = document.createElement('link');
                    link.rel = 'icon';
                    document.head.appendChild(link);
                }
                if (file.path !== null) {
                    link.href = makeImagePath(file.path);
                } else {
                    link.href = FAVICON_IMG
                }
            }

        }
    }
}

export const createImageVariables = configuration => {
    /** Logo */
    createImageVariable({
        fileType: PROMO_FILE_TYPE.LOGO,
        sectionType: PROMO_SECTION_TYPE.LOGO,
        path: configuration?.logoSection?.files?.logo
    });

    /** Favicon */
    createImageVariable({
        fileType: PROMO_FILE_TYPE.FAVICON,
        sectionType: PROMO_SECTION_TYPE.LOGO,
        path: configuration?.logoSection?.files?.favicon
    });

    /** General */
    createImageVariable({
        fileType: PROMO_FILE_TYPE.BACKGROUND,
        sectionType: PROMO_SECTION_TYPE.GENERAL_INFO,
        path: configuration?.sections?.generalInfo?.files?.background
    });

    createImageVariable({
        fileType: PROMO_FILE_TYPE.BANNER,
        sectionType: PROMO_SECTION_TYPE.GENERAL_INFO,
        path: configuration?.sections?.generalInfo?.files?.banner
    });

    /** Earn Process */
    createImageVariable({
        fileType: PROMO_FILE_TYPE.BACKGROUND,
        sectionType: PROMO_SECTION_TYPE.EARN_PROCESS,
        path: configuration?.sections?.earnProcess?.files?.background
    });

    /** Marketing Tools */
    createImageVariable({
        fileType: PROMO_FILE_TYPE.BACKGROUND,
        sectionType: PROMO_SECTION_TYPE.MARKETING_TOOLS,
        path: configuration?.sections?.marketingTools?.files?.background
    });

    createImageVariable({
        fileType: PROMO_FILE_TYPE.BANNER,
        sectionType: PROMO_SECTION_TYPE.MARKETING_TOOLS,
        path: configuration?.sections?.marketingTools?.files?.banner
    });

    [
        PROMO_FILE_TYPE.LOGO_1,
        PROMO_FILE_TYPE.LOGO_2,
        PROMO_FILE_TYPE.LOGO_3,
        PROMO_FILE_TYPE.LOGO_4,
        PROMO_FILE_TYPE.LOGO_5,
        PROMO_FILE_TYPE.BACKGROUND_1,
        PROMO_FILE_TYPE.BACKGROUND_2,
        PROMO_FILE_TYPE.BACKGROUND_3,
        PROMO_FILE_TYPE.BACKGROUND_4,
        PROMO_FILE_TYPE.BACKGROUND_5,
    ].forEach(fileType => {
        createImageVariable({
            fileType,
            sectionType: PROMO_SECTION_TYPE.MARKETING_TOOLS,
            path: configuration?.sections?.marketingTools?.files[PROMO_FILE_NAMES[fileType]]
        });
    })

    /** Testimonial */
    createImageVariable({
        fileType: PROMO_FILE_TYPE.BACKGROUND,
        sectionType: PROMO_SECTION_TYPE.TESTIMONIAL,
        path: configuration?.sections?.testimonial?.files?.background
    });

    [
        PROMO_FILE_TYPE.LOGO_1,
        PROMO_FILE_TYPE.LOGO_2,
        PROMO_FILE_TYPE.LOGO_3,
        PROMO_FILE_TYPE.LOGO_4,
        PROMO_FILE_TYPE.LOGO_5,
        PROMO_FILE_TYPE.LOGO_6,
        PROMO_FILE_TYPE.LOGO_7,
        PROMO_FILE_TYPE.LOGO_8,
        PROMO_FILE_TYPE.LOGO_9,
        PROMO_FILE_TYPE.LOGO_10,
    ].forEach(fileType => {
        createImageVariable({
            fileType,
            sectionType: PROMO_SECTION_TYPE.TESTIMONIAL,
            path: configuration?.sections?.testimonial?.files?.[PROMO_FILE_NAMES[fileType]]
        });
    })

    /** Faq */
    createImageVariable({
        fileType: PROMO_FILE_TYPE.BACKGROUND,
        sectionType: PROMO_SECTION_TYPE.FAQ,
        path: configuration?.sections?.faq?.files?.background
    });
}

export const createCSSVariables = configuration => {

    createFontFamilyVariable(configuration.fontFamily);

    createColorVariables(configuration.colors);

    createImageVariables(configuration);
}

export const createSeoTags = seo => {
    let description = document.querySelector("meta[name='description']");
    if (!description) {
        description = document.createElement('meta');
        description.name = 'description';
        document.head.prepend(description);
    }
    description.content = seo.description;

    let keywords = document.querySelector("meta[name='keywords']");
    if (!keywords) {
        keywords = document.createElement('meta');
        keywords.name = 'keywords';
        document.head.prepend(keywords);
    }
    keywords.content = seo.keywords;

    document.title = seo.title;
}

export const getPromoSectionName = sectionType => {
    switch (sectionType) {
        case PROMO_SECTION_TYPE.BRANDS:
            return "brands";
        case PROMO_SECTION_TYPE.COMMISSION:
            return "commission";
        case PROMO_SECTION_TYPE.EARN_PROCESS:
            return "earnProcess";
        case PROMO_SECTION_TYPE.FAQ:
            return "faq";
        case PROMO_SECTION_TYPE.FOOTER:
            return "footer";
        case PROMO_SECTION_TYPE.GENERAL_INFO:
            return "generalInfo";
        case PROMO_SECTION_TYPE.MARKETING_TOOLS:
            return "marketingTools";
        case PROMO_SECTION_TYPE.SEO:
            return "seo";
        case PROMO_SECTION_TYPE.STATISTICS:
            return "statistics";
        case PROMO_SECTION_TYPE.STORIES:
            return "stories";
        case PROMO_SECTION_TYPE.TESTIMONIAL:
            return "testimonial";
        case PROMO_SECTION_TYPE.COMMON:
            return "common";
        default:
            return "";
    }
}

