import React, {useEffect, Fragment, Suspense } from 'react';

import { connect } from "react-redux";
import { useParams } from "react-router-dom";

import { isRTL } from 'utils/common';

const RTL = React.lazy(() => import('./rtl'))
const LTR = React.lazy(() => import('./ltr'))


import configurationType from "types/configuration.type";

const Styles = ({
    configuration
}) => {

    const searchParams = useParams();
    const currentLn = (searchParams.lang || (configuration?.defaultLanguageCode ?? "")).toUpperCase();

    useEffect(() => {
        if (currentLn) {
            if (isRTL(currentLn)) {
                document.body.classList.add('rt--rtl');
            }
        }
    }, [currentLn])

    return currentLn ?
        isRTL(currentLn) ? <Suspense fallback={<Fragment />}><RTL /></Suspense> : <Suspense fallback={<Fragment />}><LTR /></Suspense>
        : <Suspense fallback={<Fragment />}><LTR /></Suspense>
}

/** Styles propTypes
    * PropTypes
*/
Styles.propTypes = {
    /** Redux state property, configuration */
    configuration: configurationType
}

const mapStateToProps = (state) => {
    return {
        configuration: state.common.configuration
    };
};



export default connect(mapStateToProps, null)(Styles);