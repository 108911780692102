import React from 'react';

import { connect } from "react-redux";

import useText from 'hooks/useText';
import { PROMO_SECTION_TYPE } from 'constants/common.constants';

import { makeContactUrl } from "utils/common"

import Gmail from 'assets/images/promo/icon-gmail.svg'
import Skype from 'assets/images/promo/icon-skype.svg'
import Whatsapp from 'assets/images/promo/icon-whatsapp.svg'
import Telegram from 'assets/images/promo/icon-telegram.svg'
import Viber from 'assets/images/promo/icon-viber.svg'
import Facebook from 'assets/images/promo/icon-facebook.svg'
import Instagram from 'assets/images/promo/icon-instagram.svg'
import Twitter from 'assets/images/promo/icon-twitter.svg'

import configurationType from "types/configuration.type";

const Footer = ({
    configuration
}) => {
    const { t } = useText();

    const mapping = {
        "email": Gmail,
        "facebook": Facebook,
        "instagram": Instagram,
        "skype": Skype,
        "telegram": Telegram,
        "twitter": Twitter,
        "viber": Viber,
        "whatsApp": Whatsapp,
    }

    const contacts = configuration?.contactSection?.contacts ?? {};

    const hasContact = Object.keys(contacts).length > 0;

    const currentYear = new Date().getFullYear();

    return (
        <div className="rt--promo-footer" id={PROMO_SECTION_TYPE.FOOTER}>
            <div className='rt--promo-footer-block rt--promo-block-gap'>
                <div className='rt--promo-footer-block-content rt--flex rt--align-center rt--justify-between'>
                    <div className='rt--flex rt--flex-col rt--align-left'>
                        <div className='rt--promo-footer-block-content-logo'></div>
                        <span className='rt--small-color rt--font-10 rt--font-extra-bold rt--mt-12'>©Centrivo Affiliate {currentYear}</span>
                    </div>
                    <div className='rt--promo-footer-block-content-info rt--flex'>
                        <div className='rt--flex rt--flex-col'>
                            <span className='rt--title-color rt--font-24 rt--font-extra-bold'>
                                {
                                    t(PROMO_SECTION_TYPE.COMMON, "contacts")
                                }
                            </span>
                            {
                                hasContact && (
                                    <div className='rt--promo-footer-block-content-icons rt--flex rt--mt-20 rt--mb-16'>
                                        {
                                            Object.keys(contacts).map(contact => (
                                                <a 
                                                    href={makeContactUrl(contact, contacts[contact])} 
                                                    key={contact}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    <img src={mapping[contact]} alt={contact} />
                                                </a>
                                            ))
                                        }
                                    </div>
                                )
                            }

                        </div>
                        <div className='rt--flex rt--flex-col'>
                            <div className='rt--mb-8'>
                                <a href="#privacy">
                                    <span className='rt--theme-color rt--font-12 rt--font-extra-bold'>
                                        {
                                            t(PROMO_SECTION_TYPE.COMMON, "privacyPolicy")
                                        }
                                    </span>
                                </a>
                            </div>
                            <div>
                                <a href="#terms">
                                    <span className='rt--theme-color rt--font-12 rt--font-extra-bold'>
                                        {
                                            t(PROMO_SECTION_TYPE.COMMON, "termsAndConditions")
                                        }
                                    </span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

/** Footer propTypes
    * PropTypes
*/
Footer.propTypes = {
    /** Redux state property, configuration */
    configuration: configurationType
}

const mapStateToProps = (state) => {
    return {
        configuration: state.common.configuration
    };
};

export default connect(mapStateToProps, null)(Footer);