import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import Slider from "react-slick";

import { PROMO_SECTION_TYPE } from 'constants/common.constants';

const Brands = ({
    data
}) => {

    const brandsData = data ? JSON.parse(data) : [];

    const activeBrands = brandsData.filter(brand => brand.enabled);
    const brandCounts = activeBrands.length;

    const sliderSettings = {
        dots: false,
        infinite: true,
        speed: 300,
        slidesToShow: Math.min(brandCounts, 4),
        centerMode: true,
        autoplay: true,
        arrows: false,
        responsive: [
            {
                breakpoint: 1440,
                settings: {
                    slidesToShow: Math.min(brandCounts, 3),
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: Math.min(brandCounts, 2),
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: Math.min(brandCounts, 1),
                }
            }
        ]
    }

    if (activeBrands.length === 0) return <Fragment />

    return (
        <div className="rt--promo-brands" id={PROMO_SECTION_TYPE.BRANDS}>
            <Slider {...sliderSettings}>
                {
                    activeBrands.map(brand => (
                        <div 
                            className='rt--promo-brands-item rt--pl-34 rt--pr-34 rt--flex rt--align-center rt--justify-center' key={brand.id}
                            title={brand.name}
                            onClick={() => {
                                window.open(brand.url)
                            }}
                        >
                            <h6 className='rt--font-26 rt--font-extra-bold'>{brand.name}</h6>
                        </div>
                    ))
                }
            </Slider>

        </div>
    );
}

Brands.propTypes = {
    data: PropTypes.string,
}

export default Brands; 