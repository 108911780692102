import React, { Fragment, useEffect, useRef } from 'react';
import PropTypes from "prop-types";

import { connect } from 'react-redux';
import { Outlet } from 'react-router';
import { useParams } from "react-router-dom";

import { debounce } from 'utils/common';

import Global from "components/global";
import Styles from 'components/styles';

import Loader from 'components/common/loader';
import NotFound from 'components/common/notFound';
import Header from 'components/common/header';
import Footer from 'components/common/footer';

import { getConfiguration } from 'store/actions/common.actions';

/** Main Layout Component */
const Layout = ({
    getConfiguration,
    notFound,
    translationsLoaded
}) => {

    const menuRef = useRef(null);

    const searchParams = useParams();

    const currentLn = searchParams.lang?.toUpperCase() ?? null;

    const stickMenuToTopOfDisplay = debounce(() => {
        if (menuRef.current?.classList?.contains('stick-menu')) {
            menuRef.current.classList.remove('stick-menu');
        }
    }, 100);

    useEffect(() => {
        window.addEventListener("scroll", () => {
            if (menuRef.current?.classList?.contains('stick-menu')) {
                return;
            }

            menuRef.current?.classList?.add('stick-menu');
        });

        window.addEventListener("scrollend", stickMenuToTopOfDisplay);

        return () => {
            window.removeEventListener("scroll", stickMenuToTopOfDisplay);
        }
    }, [])

    /** Get Configuration */
    useEffect(() => {
        getConfiguration(currentLn)
    }, [])

    return (
        <div>
            {
                !translationsLoaded ?
                    <Loader />
                    : notFound ?
                        <NotFound /> : (
                            <Fragment>
                                <Styles />
                                <Global />
                                <div className="rt--promo rt--general-background-color">
                                    <Header ref={menuRef} />
                                    <Outlet />
                                    <Footer />
                                </div>
                                
                            </Fragment>
                        )
            }

        </div>
    )
}

/** Layout propTypes
    * PropTypes
*/
Layout.propTypes = {
    /** Redux action to load configuration */
    getConfiguration: PropTypes.func,
    /** Redux state property, is true when should show not found page */
    notFound: PropTypes.bool,
    /** Redux state property, is true when translations loaded */
    translationsLoaded: PropTypes.bool
}

const mapDispatchToProps = dispatch => (
    {
        getConfiguration: languageCode => {
            dispatch(getConfiguration(languageCode))
        }
    }
)

const mapStateToProps = state => {
    return {
        translationsLoaded: state.common.translationsLoaded,
        notFound: state.common.notFound,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Layout);