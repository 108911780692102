import axios from 'axios';

import store from 'store/configureStore';

import { setNotFound } from 'store/actions/common.actions';

import { showError } from 'utils/message';

import { initTranslations } from 'translations/config'

import { RESPONSE_CODES } from 'constants/responseCode.constants';
import { getAPIUrls } from './common';

axios.defaults.baseURL = getAPIUrls().API_URL;

axios.interceptors.request.use(config => {

    // if (config.method === "get") {
    //     config.url = config.url.indexOf("?") > -1 ? config.url + "&timestump=" + Date.now() : config.url + "?timestump=" + Date.now()
    // }

    //Set default content type
    if (!config.headers['Content-Type'] || config.headers['Content-Type'] === 'text/plain;charset=utf-8') {
        config.headers['Content-Type'] = 'application/json;charset=utf-8';
    }

    // Trim params and data

    if (config.params) {
        const newParams = {};

        Object.keys(config.params).map(key => {
            if (typeof config.params[key] === "string") {
                if (key && typeof key === "string" && !key.toLowerCase().includes("password")) {
                    newParams[key] = config.params[key].trim();
                } else {
                    newParams[key] = config.params[key]
                }

                if (newParams[key] === "") {
                    newParams[key] = null
                }
            } else {
                newParams[key] = config.params[key]
            }
        })
    }
    if (config.data && !(config.data instanceof FormData)) {
        try {
            const newData = {};
            let parsed = config.data;
            if (typeof config.data === 'string') {
                try {
                    parsed = JSON.parse(config.data);
                } catch (ex) { console.log("err") }
            }

            Object.keys(parsed).map(key => {
                if (typeof parsed[key] === "string") {
                    if (key && typeof key === "string" && !key.toLowerCase().includes("password")) {
                        newData[key] = parsed[key].trim();
                    } else {
                        newData[key] = parsed[key]
                    }
                    if (newData[key] === "") {
                        newData[key] = null
                    }
                } else {
                    newData[key] = parsed[key]
                }
            })
            config.data = newData;
        } catch (ex) { console.log(ex) }
    }

    return config;
}, error => {
    return Promise.reject(error);
});
axios.interceptors.response.use(({ status, data }) => {
    if (data?.resultCode !== RESPONSE_CODES.Success && !(data instanceof Blob) && !(data instanceof Array)) {
        showError(data?.message ?? '', data?.resultCode ?? 0, data?.resource ?? '');
    }
    return { status, data };
}, error => {
    if (!error.response || !error.response.data) {
        return Promise.reject(error);
    }
    let status = error.response.status;
    let errorData = error.response.data;

    if (status >= 400) {
        
        if (errorData?.resource === "PromoSite") {
            store.dispatch(setNotFound(true));
            initTranslations(
                "EN",
                null
            );
        } else {
            setTimeout(() => {
                if (store.getState().common.translationsLoaded) {
                    showError(
                        errorData?.message ?? '',
                        errorData?.resultCode ?? 0,
                        errorData?.resource ?? ''
                    );
                } else {
                    initTranslations(
                        "EN",
                        null,
                        () => {
                            showError(errorData?.message ?? '', errorData?.resultCode ?? 0, errorData?.resource ?? '');
                        }
                    );
                }
            }, 200)
        }


    }
    return Promise.reject(error);
});
