import React from 'react';

import MainComponent from 'components/main';

/** Main Route */
const MainRoute = () => {
    return (
        <div className='rt--container'>
            <MainComponent /> 
        </div> 
    )
}

export default MainRoute;