import React from 'react';
import PropTypes from 'prop-types';

import useText from 'hooks/useText';

import { PROMO_SECTION_TYPE } from 'constants/common.constants';

import RevShareIcon from 'assets/images/promo/commissionIcon1.png';
import CpaIcon from 'assets/images/promo/commissionIcon2.png';
import FixedIcon from 'assets/images/promo/commissionIcon3.png';

const Commission = ({
    data
}) => {
    const { t } = useText();

    const commissionData = data ? JSON.parse(data) : {};

    const activecommissions = Object.keys(commissionData).filter(t => commissionData[t]);

    return (
        <div className="rt--promo-commission" id={PROMO_SECTION_TYPE.COMMISSION}>
            <div className='rt--promo-commission-block rt--promo-block-gap'>
                <div className='rt--promo-commission-block-content'>
                    <div className='rt--promo-commission-block-content-title'>
                        <span className='rt--title-color rt--font-36 rt--font-extra-bold'>
                            {
                                t(PROMO_SECTION_TYPE.COMMON, "commission")
                            }
                        </span>
                    </div>
                    <div className='rt--promo-commission-block-content-info rt--background-color rt--flex rt--justify-evenly rt--mt-44'>
                        {
                            activecommissions.map(commission => (
                                <div className='rt--promo-commission-block-content-info-text rt--flex rt--flex-col rt--justify-center rt--align-center' key={commission}>
                                    <img src={
                                        commission === "1" ? RevShareIcon : 
                                        commission === "2" ? CpaIcon : FixedIcon
                                    }></img>
                                    <div className='rt--promo-commission-block-content-info-text-content rt--flex rt--flex-col rt--justify-center'>
                                        <span className='rt--title-color rt--font-24 rt--font-extra-bold rt--mb-6'>
                                            {
                                                t(PROMO_SECTION_TYPE.COMMISSION, `title${commission}`)
                                            }
                                        </span>
                                        <span className='rt--subtitle-color rt--font-14 rt--font-extra-bold rt--mb-22'>
                                            {
                                                t(PROMO_SECTION_TYPE.COMMISSION, `description${commission}`)
                                            }
                                        </span>
                                        <span className='rt--theme-color rt--font-32 rt--font-extra-bold rt--mt-22'>
                                            {
                                                t(PROMO_SECTION_TYPE.COMMISSION, `subDescription${commission}`)
                                            }
                                        </span>
                                    </div>
                                </div>
                            ))
                        }
                        
                    </div>
                </div>
            </div>
        </div>
    );
}

Commission.propTypes = {
    data: PropTypes.string,
}

export default Commission; 