import React from 'react'
import ReactDOM from 'react-dom/client'

import './assets/css/typography.css';
import './assets/css/scss/style.scss';
import './assets/css/ant.less';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

import App from './App';

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(<App />);
