import React, { Fragment, useEffect, useRef } from 'react';
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { useNavigate, useSearchParams } from 'react-router-dom';

import useText from 'hooks/useText';

import { 
    updateConfigurationDynamicData, 
    updateConfigurationEnabled, 
    updateTranslation, 
    updateConfigurationContacts,
    updateLanguages,
    updateDefaultLanguageCode 
} from "store/actions/common.actions";

import {
    createColorVariables,
    createCSSVariables,
    createFontFamilyVariable,
    createImageVariable,
    createSeoTags
} from "utils/promo";
import { isPreviewMode } from "utils/common";

import { PROMO_SECTION_TYPE, PROMO_UPDATE_TYPE } from 'constants/common.constants';

import configurationType from "types/configuration.type";

/** Global Component */
const GlobalComponent = ({
    configuration,
    updateConfigurationDynamicData,
    updateConfigurationEnabled,
    updateTranslation,
    updateConfigurationContacts,
    updateLanguages,
    updateDefaultLanguageCode
}) => {

    const navigate = useNavigate();
    const [queryStringParams] = useSearchParams();

    const { t } = useText();

    const initializedRef = useRef(false)

    /** Create style variables */
    useEffect(() => {
        if(Object.keys(configuration).length > 0 && !initializedRef.current){
            createCSSVariables(configuration);

            createSeoTags({
                title: t(PROMO_SECTION_TYPE.SEO, "Title"),
                keywords: t(PROMO_SECTION_TYPE.SEO, "Keywords"),
                description: t(PROMO_SECTION_TYPE.SEO, "Description"),
            })
            initializedRef.current = true;
        }
        
    }, [configuration])


    const changeLanguage = lang => {

        navigate({
            pathname: `/${lang.toLowerCase()}`,
            search: queryStringParams.toString(),
        })
        
        window.location.reload();
    }

    /** Initialize post message events */
    useEffect(() => {
        if (isPreviewMode()) {
            window.addEventListener("message", e => {
                const d = e.data;
                if (d) {
                    const updateType = d.type;
                    let sectionType = null;
                    let element = null;
                    switch (updateType) {
                        case PROMO_UPDATE_TYPE.COLORS:
                            createColorVariables(d?.configuration?.colors);
                            break;
                        case PROMO_UPDATE_TYPE.FONT_FAMILY:
                            createFontFamilyVariable(d?.configuration?.fontFamily);
                            break;
                        case PROMO_UPDATE_TYPE.IMAGE:
                            createImageVariable(d?.configuration);
                            break;
                        case PROMO_UPDATE_TYPE.DYNAMIC_DATA:
                            updateConfigurationDynamicData(d?.configuration?.sectionType, d?.configuration?.data);
                            break;
                        case PROMO_UPDATE_TYPE.SECTION:
                            updateConfigurationEnabled(d?.configuration?.sectionType, d?.configuration?.enabled);
                            break
                        case PROMO_UPDATE_TYPE.TEXT:
                            updateTranslation(d?.configuration?.sectionType, d?.configuration?.key, d?.configuration?.text);
                            break
                        case PROMO_UPDATE_TYPE.SCROLL_TO_SECTION:
                            sectionType = d?.configuration?.sectionType;
                            if(sectionType === "privacy" || sectionType === "terms"){
                                window.location.hash = sectionType;
                            } else {
                                element = document.querySelector(`a[data-href='${sectionType}']`);
                                if(element){
                                    element.click()
                                }
                            }
                            
                            break;
                        case PROMO_UPDATE_TYPE.CONTACTS:
                            updateConfigurationContacts(d?.configuration?.data);
                            break;
                        case PROMO_UPDATE_TYPE.LANGUAGE:
                            changeLanguage(d?.configuration?.language);
                            break;
                        case PROMO_UPDATE_TYPE.LANGUAGES_LIST:
                            updateLanguages(d?.configuration?.languages);
                            break;
                        case PROMO_UPDATE_TYPE.DEFAULT_LANGUAGE_CODE:
                            updateDefaultLanguageCode(d?.configuration?.language);
                            break;
                        default:
                            break;
                    }
                }
            })
        }
    }, [])

    return (
        <Fragment />
    )
}

/** GlobalComponent propTypes
    * PropTypes
*/
GlobalComponent.propTypes = {
    /** Redux state property, configuration */
    configuration: configurationType,
    /** Redux action to update section dynamic data */
    updateConfigurationDynamicData: PropTypes.func,
    /** Redux action to update section enable state */
    updateConfigurationEnabled: PropTypes.func,
    /** Redux action to update contacts */
    updateConfigurationContacts: PropTypes.func,
    /** Redux action to update section translation */
    updateTranslation: PropTypes.func,
    /** Redux action to update languages list */
    updateLanguages: PropTypes.func,
    /** Redux action to update default language code */
    updateDefaultLanguageCode: PropTypes.func
}

const mapStateToProps = (state) => {
    return {
        configuration: state.common.configuration
    };
};

const mapDispatchToProps = dispatch => (
    {
        updateConfigurationDynamicData: (sectionType, data) => {
            dispatch(updateConfigurationDynamicData(sectionType, data))
        },

        updateConfigurationEnabled: (sectionType, enabled) => {
            dispatch(updateConfigurationEnabled(sectionType, enabled))
        },

        updateTranslation: (sectionType, key, text) => {
            dispatch(updateTranslation(sectionType, key, text))
        },

        updateConfigurationContacts: contacts => {
            dispatch(updateConfigurationContacts(contacts))
        },

        updateLanguages: languages => {
            dispatch(updateLanguages(languages))
        },

        updateDefaultLanguageCode: language => {
            dispatch(updateDefaultLanguageCode(language))
        }
    }
)

export default connect(mapStateToProps, mapDispatchToProps)(GlobalComponent);