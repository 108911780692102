import React from 'react';
import PropTypes from 'prop-types';

import { Collapse } from 'antd';

import useText from 'hooks/useText';

import { PROMO_SECTION_TYPE } from 'constants/common.constants';

const FAQ = ({
    data
}) => {

    const { t } = useText();

    const faqData = data ? JSON.parse(data) : {};

    const activeFAQs = Object.keys(faqData).filter(t => faqData[t])

    return (
        <div className="rt--promo-faq" id={PROMO_SECTION_TYPE.FAQ}>
            <div className='rt--promo-faq-block rt--promo-block-gap'>
                <div className='rt--promo-faq-block-content rt--align-center rt--justify-between'>
                    <div className='rt--promo-faq-block-content-title'>
                        <span className='rt--title-color rt--font-36 rt--font-extra-bold'>
                            {
                                t(PROMO_SECTION_TYPE.COMMON, "faq")
                            }
                        </span>
                    </div>
                    <div className='rt--promo-faq-block-content-collapse rt--mt-44'>
                        <Collapse
                            ghost
                            expandIconPosition="right"
                            expandIcon={({ isActive }) =>
                                <div className='rt--promo-faq-expand rt--flex rt--align-center rt--justify-center'>
                                    {isActive ? <i className='icon-promo-minus'></i> : <i className='icon-promo-plus'></i>}
                                </div>

                            }
                        >
                            {
                                activeFAQs.map(f => (
                                    <Collapse.Panel
                                        key={f}
                                        header={
                                            <span className='rt--small-color rt--font-24 rt--font-extra-bold'>
                                                {
                                                    t(PROMO_SECTION_TYPE.FAQ, `title${f}`)
                                                }
                                            </span>
                                        }
                                    >
                                        <span className='rt--subtitle-color rt--font-extra-bold'>
                                            {
                                                t(PROMO_SECTION_TYPE.FAQ, `description${f}`)
                                            }
                                        </span>
                                    </Collapse.Panel>
                                ))
                            }

                        </Collapse>
                    </div>
                </div>
            </div>
        </div>
    );
}

FAQ.propTypes = {
    data: PropTypes.string,
}

export default FAQ; 