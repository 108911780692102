import React from 'react';
import PropTypes from 'prop-types';

import useText from 'hooks/useText';

import { PROMO_SECTION_TYPE } from 'constants/common.constants';

import Slider from "react-slick";

const Testimonials = ({
    data
}) => {
    const { t } = useText();

    const testimonialsData = data ? JSON.parse(data) : {};

    const activeTestimonials = Object.keys(testimonialsData).filter(t => testimonialsData[t])

    const activeTestimonialsCount = activeTestimonials.length;

    const sliderSettings = {
        dots: false,
        infinite: true,
        speed: 300,
        slidesToShow: Math.min(activeTestimonialsCount, 3),
        slidesToScroll: 1,
        autoplay: true,
        arrows: false,
        responsive: [
            {
                breakpoint: 1440,
                settings: {
                    slidesToShow: Math.min(activeTestimonialsCount, 2),
                }
            },
            {
                breakpoint: 800,
                settings: {
                    slidesToShow: Math.min(activeTestimonialsCount, 1),
                }
            }
        ]
    }

    return (
        <div className="rt--promo-testimonials" id={PROMO_SECTION_TYPE.TESTIMONIAL}>
            <div className='rt--promo-testimonials-block rt--promo-block-gap'>
                <div className='rt--promo-testimonials-block-content'>
                    <div className='rt--promo-testimonials-block-content-title'>
                        <span className='rt--title-color rt--font-36 rt--font-extra-bold'>
                            {
                                t(PROMO_SECTION_TYPE.COMMON, "testimonials")
                            }
                        </span>
                    </div>
                    <div className='rt--promo-testimonials-block-content-info rt--mt-44'>
                        <Slider {...sliderSettings}>
                            {
                                activeTestimonials.map(testimonial => (
                                    <div 
                                        className='rt--promo-testimonials-block-content-info-sec rt--background-color rt--flex rt--flex-equal rt--align-center rt--justify-center'
                                        key={testimonial}
                                    >
                                        <div className='rt--promo-testimonials-block-content-info-text rt--flex rt--flex-col rt--justify-center rt--align-center'>
                                            <span className='rt--title-color rt--font-18 rt--font-extra-bold'>
                                                {
                                                    t(PROMO_SECTION_TYPE.TESTIMONIAL, `description${testimonial}`)
                                                }
                                            </span>
                                            <div 
                                                className='rt--promo-testimonials-block-content-info-sec-image rt--mt-24 rt--mb-14'
                                                data-index={testimonial}
                                            />
                                            <span className='rt--small-color rt--font-12 rt--font-bold'>
                                                {
                                                    t(PROMO_SECTION_TYPE.TESTIMONIAL, `title${testimonial}`)
                                                }
                                            </span>
                                            {/* <span className='rt--subtitle-color rt--font-18 rt--font-regular rt--mt-6'>
                                                {
                                                    t(PROMO_SECTION_TYPE.TESTIMONIAL, `name${testimonial}`)
                                                }
                                            </span> */}
                                        </div>
                                    </div>
                                ))
                            }
                        </Slider>
                    </div>
                </div>
            </div>
        </div>
    );
}

Testimonials.propTypes = {
    data: PropTypes.string,
}

export default Testimonials; 