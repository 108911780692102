import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';

import { Modal } from 'antd';

import ReactStories from 'react-insta-stories';

import { makeImagePath } from 'utils/promo';

import { PROMO_SECTION_TYPE } from 'constants/common.constants';

const Stories = ({
    data
}) => {

    const [showingStory, setShowingStory] = useState(null);

    const storiesData = data ? JSON.parse(data) : [];

    const activeStories = storiesData.filter(story => story.enabled);

    const storiesCounts = activeStories.length;

    const closeStory = () => {
        setShowingStory(null);
    };

    const storiesList = showingStory ? Object.values(showingStory.stories).map(st => makeImagePath(st)) : [];
    
    if (storiesCounts === 0) return <Fragment />;

    return (
        <div className="rt--promo-stories rt--flex rt--align-center rt--justify-center" id={PROMO_SECTION_TYPE.STORIES}>
            <div className='rt--promo-stories-block'>
                <div className='rt--promo-stories-block-content rt--flex rt--align-center rt--justify-center'>
                    {
                        activeStories.map(story => {
                            return (
                                <div
                                    key={story.id}
                                    onClick={() => {
                                        const storiesCount = story.stories ? Object.keys(story.stories).length : 0;
                                        if(storiesCount > 0){
                                            setShowingStory(story);
                                        }
                                    }}
                                    className='rt--promo-stories-item rt--flex rt--flex-col rt--align-center rt--justify-center rt--pl-12 rt--pr-12'
                                >
                                    <div className='rt--promo-stories-item-border'>
                                        <div className='rt--promo-stories-item-border-bg'>
                                            <img src={makeImagePath(story.imageUrl)} alt={story.name} />
                                        </div>
                                    </div>
                                    <div className='rt--promo-stories-item-title'>
                                        <span className='rt--subtitle-color rt--font-12 rt--font-regular'>{story.name}</span>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>


            {
                showingStory && (
                    <Modal
                        visible={true}
                        className='rt--promo-stories-modal'
                        width={480}
                        onOk={closeStory}
                        onCancel={closeStory}
                    >
                        <ReactStories
                            stories={storiesList}
                            defaultInterval={6000}
                            width={'100%'}
                            height={'100%'}
                            onAllStoriesEnd={closeStory}
                        />
                    </Modal>
                )
            }
        </div>
    );
}

Stories.propTypes = {
    data: PropTypes.string,
}

export default Stories; 