import React from 'react';
import PropTypes from 'prop-types';

import useText from 'hooks/useText';

import { PROMO_SECTION_TYPE } from 'constants/common.constants';

const ITEMS = [1, 2, 3, 4, 5];

const Marketing = ({
    data
}) => {
    const { t } = useText();

    const marketingData = data ? JSON.parse(data) : {};
    const dataWithFixedIndexes = Object.values(marketingData).map((enabled, index) => ({
        enabled,
        index: index + 1,
    }))

    const enabledSections = dataWithFixedIndexes.filter(data => data.enabled)

    const mainItem = enabledSections[0];
    const secondaryItems = enabledSections.filter((_, index) => index !== 0);

    return (
        <div className="rt--promo-marketing" id={PROMO_SECTION_TYPE.MARKETING_TOOLS}>
            <div className='rt--promo-marketing-block rt--promo-block-gap'>
                <div className='rt--promo-marketing-block-content'>
                    <div className='rt--promo-marketing-block-content-title'>
                        <span className='rt--title-color rt--font-36 rt--font-extra-bold'>
                            {
                                t(PROMO_SECTION_TYPE.COMMON, "marketingTools")
                            }
                        </span>
                    </div>
                    <div className='rt--promo-marketing-block-content-sec rt--flex rt--flex-wrap rt--mt-44'>
                        {
                            mainItem && (
                                <div
                                    className='rt--promo-marketing-block-content-banner rt--promo-marketing-block-content-banner-1 rt--flex rt--flex-equal'
                                    data-index={`background-${mainItem.index}`}
                                >
                                    <div className='rt--flex rt--flex-col rt--flex-equal'>
                                        <div
                                            className='rt--promo-marketing-block-content-banner-icon'
                                            data-index={`logo-${mainItem.index}`}
                                        />
                                        <span className='rt--title-color rt--font-24 rt--font-extra-bold rt--mt-20 rt--mb-10'>
                                            {
                                                t(PROMO_SECTION_TYPE.MARKETING_TOOLS, `title${mainItem.index}`)
                                            }
                                        </span>
                                        <span className='rt--subtitle-color rt--font-14 rt--font-24 rt--font-extra-bold'>
                                            {
                                                t(PROMO_SECTION_TYPE.MARKETING_TOOLS, `description${mainItem.index}`)
                                            }
                                        </span>
                                        <div className='rt--promo-marketing-block-content-banner-img rt--mt-40 rt--flex rt--justify-center rt--align-center'>

                                        </div>
                                    </div>
                                </div>
                            )
                        }

                        <div className='rt--promo-marketing-block-content-sec-two rt--flex rt--flex-equal rt--flex-wrap'>
                            {
                                secondaryItems.map(item => (
                                    <div
                                        className='rt--promo-marketing-block-content-banner rt--promo-marketing-block-content-banner-2 rt--flex rt--flex-equal'
                                        key={item.index}
                                        data-index={`background-${item.index}`}
                                    >
                                        <div className='rt--flex rt--flex-col'>
                                            <div
                                                className='rt--promo-marketing-block-content-banner-icon'
                                                data-index={`logo-${item.index}`}
                                            />
                                            <span className='rt--title-color rt--font-24 rt--font-extra-bold rt--mt-20 rt--mb-10'>
                                                {
                                                    t(PROMO_SECTION_TYPE.MARKETING_TOOLS, `title${item.index}`)
                                                }
                                            </span>
                                            <span className='rt--subtitle-color rt--font-14 rt--font-24 rt--font-extra-bold'>
                                                {
                                                    t(PROMO_SECTION_TYPE.MARKETING_TOOLS, `description${item.index}`)
                                                }
                                            </span>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

Marketing.propTypes = {
    data: PropTypes.string,
}

export default Marketing; 