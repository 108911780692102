import React from 'react';
import PropTypes from 'prop-types';

import useText from 'hooks/useText';

import { PROMO_SECTION_TYPE } from 'constants/common.constants';

import signUpOne from 'assets/images/sign-up-one.svg';
import promoteTwo from 'assets/images/promote-two.svg';
import earnThree from 'assets/images/earn-three.svg';

const EarnProcess = ({
    data
}) => {

    const { t } = useText();

    const earnProcessData = data ? JSON.parse(data) : {};

    const activeEarnProcess = Object.keys(earnProcessData).filter(t => earnProcessData[t]);

    return (
        <div className="rt--promo-earnProcess" id={PROMO_SECTION_TYPE.EARN_PROCESS}>
            <div className='rt--promo-earnProcess-block rt--promo-block-gap'>
                <div className='rt--promo-earnProcess-block-content rt--flex rt--justify-between rt--flex-wrap'>
                    {
                        activeEarnProcess.map(earn => (
                            <div className='rt--promo-earnProcess-block-content-info rt--background-color rt--flex rt--flex-equal rt--align-center rt--justify-center' key={earn}>
                                <div className='rt--promo-earnProcess-block-content-info-text rt--flex rt--flex-col rt--justify-center rt--align-center'>
                                    <img 
                                        className='rt--mb-22' 
                                        src={ earn === "1" ? signUpOne : earn === "2" ? promoteTwo : earnThree } 
                                    />
                                    <span className='rt--title-color rt--font-32 rt--font-extra-bold rt--mb-12'>
                                        {
                                            t(PROMO_SECTION_TYPE.EARN_PROCESS, `title${earn}`)
                                        }
                                    </span>
                                    <span className='rt--subtitle-color rt--font-14 rt--font-bold'>
                                        {
                                            t(PROMO_SECTION_TYPE.EARN_PROCESS, `description${earn}`)
                                        }
                                    </span>
                                </div>
                            </div>
                        ))
                    }

                </div>
            </div>
        </div>
    );
}

EarnProcess.propTypes = {
    data: PropTypes.string,
}

export default EarnProcess; 