import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Fetch from 'i18next-fetch-backend';

import store from 'store/configureStore';

import { setTranslationsLoaded } from "store/actions/common.actions";

const buildTranslationUrl = path => {
	return `${import.meta.env.SYSTEM_CDN_URL}/${path}`
}

const buildSystemTranslationUrl = language => {
	return `system/translations/promo_${language.toLowerCase()}.json`
}

export const initTranslations = ( language, path, onSuccess ) => {
	let BackEndAPI = Fetch;

	const currentLang = language || "en";
	const configuration = {
		debug: false,
		interpolation: {
			escapeValue: false
		},
		react: {
			useSuspense: false
		},
        fallbackLng: language
	}

	if (!path) {
		path = buildSystemTranslationUrl(currentLang)
	}

	configuration.backend = {
		loadPath: buildTranslationUrl(path)
	}

	i18n
		.use(BackEndAPI)
		.use(initReactI18next)
		.init(configuration).then(() => {
			store.dispatch(setTranslationsLoaded());
			onSuccess && onSuccess();
		}).catch(err => {
			console.log(err)
		});
}

export default i18n;