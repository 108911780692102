export const RESPONSE_CODES = {
    Success: 0,
    Exist: 1,
    EmailExists: 2,
    NotFound : 3,
    NotCreated: 4,
    NotSaved: 5,
    ImpermissibleType: 6,
    Duplicate: 7,
    NotAllowed : 8,
    LimitExceeded : 9,
    FileNotFound : 10,
    WrongToken: 11,
    WrongLoginParameters: 12,
    Unauthorized: 13,
    DontHavePermission: 14,
    NotEnoughBalance : 15,
    InvalidParameters: 16,
    InternalServerError : 17,
    InvalidRedemptionDate: 18,
    AttempsLimitReached: 19,
    PasswordExpired: 20,
    NotAvailable: 21,
    UsedPassword: 22,
    InvalidCaptcha: 23,
    InvalidSign: 24,
    InvalidProject: 25,
    AlreadyLogged: 26,
    MaintenanceModeOn: 27,
    CannotBeDeleted: 28,
    ActionRollbacked: 29,
    AccountLoginLocked: 30,
    AccountLoginTemporaryLocked: 31
}