import React, { Fragment } from 'react';

import { connect } from "react-redux";
import { useLocation } from 'react-router';

import General from './General';
import Brands from './Brands';
import Statistics from './Statistics';
import Stories from './Stories';
import EarnProcess from './EarnProcess';
import Commission from './Commission';
import Marketing from './Marketing';
import Testimonials from './Testimonials';
import Faq from './Faq';
import Terms from "./Terms";
import Privacy from "./Privacy";

import configurationType from "types/configuration.type";

/** Login Page Component */
const PromoComponent = ({
    configuration
}) => {

    const sections = configuration?.sections;

    const { hash } = useLocation();

    return (
        <div>
            {
                hash === "#terms" ? (
                    <Terms />
                ) : hash === "#privacy" ? (
                    <Privacy />
                ) : (
                    <Fragment>
                        {
                            sections?.generalInfo?.enabled && (
                                <General />
                            )
                        }

                        {
                            sections?.brands?.enabled && (
                                <Brands
                                    data={sections?.brands?.dynamicData}
                                />
                            )
                        }

                        {
                            sections?.stories?.enabled && (
                                <Stories
                                    data={sections?.stories?.dynamicData}
                                />
                            )
                        }

                        {
                            sections?.earnProcess?.enabled && (
                                <EarnProcess
                                    data={sections?.earnProcess?.dynamicData}
                                />
                            )
                        }

                        {
                            sections?.statistics?.enabled && (
                                <Statistics
                                    data={sections?.statistics?.dynamicData}
                                />
                            )
                        }

                        {
                            sections?.commission?.enabled && (
                                <Commission
                                    data={sections?.commission?.dynamicData}
                                />
                            )
                        }

                        {
                            sections?.marketingTools?.enabled && (
                                <Marketing
                                    data={sections?.marketingTools?.dynamicData}
                                />
                            )
                        }

                        {
                            sections?.testimonial?.enabled && (
                                <Testimonials
                                    data={sections?.testimonial?.dynamicData}
                                />
                            )
                        }

                        {
                            sections?.faq?.enabled && (
                                <Faq
                                    data={sections?.faq?.dynamicData}
                                />
                            )
                        }
                    </Fragment>
                )
            }
        </div>
    )
}

/** PromoComponent propTypes
    * PropTypes
*/
PromoComponent.propTypes = {
    /** Redux state property, configuration */
    configuration: configurationType
}

const mapStateToProps = (state) => {
    return {
        configuration: state.common.configuration
    };
};

export default connect(mapStateToProps, null)(PromoComponent);